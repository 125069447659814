<template>
  <transition name="fade">
    
    <ErrorDialog
          v-if="error"
          :error="error"
          :message="errorMessage"
          @exitError="extiError"
        />
    <v-dialog v-model="showDialog" max-width="300" class="rounded-xl">
      <v-card class="pb-5" rounded="xl">
        <v-card-title
          class="text-h5 teal--text text--darken-3 d-flex justify-space-between pt-0 pe-0"
        >
          <v-spacer></v-spacer>
          <CloseBtn
            size="30"
            :callback="close"
          />
        </v-card-title>

        <v-card-text class="text-center">
          <v-img width="100" class="mx-auto" :src="require('@/assets/dailyGame/hamochi.png')"></v-img>
          <div class="text-center rounded-xl text-h6">
            You need to spend a ladder to get to the next level
          </div>
          <div class="spent-ladder text-center d-flex mx-auto pa-2">
            <span class="text-h5">-</span>
            <v-img
              contain
              width="30"
              :src="require('@/assets/item/C000030.png')"
            ></v-img>
          </div>
          <div class="text-center">
            <v-btn
              @click="clickOk()"
              elevation="2"
              color="yellow"
              class="px-10 text-h6"
              :loading="ladderApi.isLoading"
            >
              {{ $t("action.ok") }}
            </v-btn>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
  </transition>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    CloseBtn: () =>
      import(
        /* webpackChunkName: "component-btn-close" */ "@/components/btns/CloseBtn"
      ),
      ErrorDialog: () =>
      import(
        /* webpackChunkName: "component-error-dialog" */ "@/components/ErrorDialog.vue"
      ),
  },
  props: ["callbackOk","callbackClose"],
  computed: mapState({
    settings: (state) => state.settings.data,
  }),
  data: () => ({
    showDialog: true,
    ladderApi: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    error: false,
  }),
  created(){
    this.ladderApi.url =
      this.$api.servers.game +
      "/api/v1/" +
      this.$_getLocale() +
      "/game/ladder/spend";

    this.ladderApi.callbackReset = () => {
      this.ladderApi.isLoading = true;
      this.ladderApi.isError = false;
    };

    this.ladderApi.callbackError = (e) => {
      this.ladderApi.isLoading = false;
      this.ladderApi.isError = true;
      this.ladderApi.error = e;
      this.errorMessage =
          "Not enough ladder. Come back and collect your new ladders tomorrow.";
        this.error = true;
        
    };
    this.ladderApi.callbackSuccess = () => {
      this.ladderApi.isLoading = false;
      this.callbackOk()
    };
  },
  mounted() {
   
  },
  methods:{
    clickOk(){
      this.ladderApi.params = {
        quantity: 1,
    };
      this.$api.fetch(this.ladderApi);
    },
    close(){
      this.callbackClose()
    },
    extiError(){
      this.callbackClose()
      this.error = false
    }
  }
};
</script>
<style>
.spent-ladder {
  width: 50px;
}
</style>
